#footer {
    background-color: #3B3B3A;
    padding: 2em 1em 1em 1em;
    position: relative;
    z-index: 1;

    & > div {
        margin-bottom: 1em;
    }
    
    .footer {
        
        &-heading {
            margin: 0 auto;
            color: #fff;
            font-size: 18px;
            font-weight: bold;
            line-height: 22px;
            font-family: "Helvetica", sans-serif;
        }

        &-social {
            list-style: none;
            padding-left: 0;
            color: #fff;
            margin: 1em 0;

            & > li {
                display: inline-block;
            }

            &--item {
                margin-right: 1.5em;

                a {
                    color: #fff;

                    &:hover {
                        color: darken(#fff,10);
                    }
                }
                
                & .fab,
                & .fa {
                    position: relative;
                    font-size: 0.8em;
                    z-index: 999;

                    &:after {
                        content: "";
                        width: 100%;
                        height: 100%;
                        padding: 1em;
                        position: absolute;
                        border-radius: 50vw;
                        left: -11px;
                        top: -8px;
                        z-index: -1;
                    }
                    
                    &.fa-facebook {


                    
                        &:after {
                            background-color: #4975AC;
                        }
                    }
                    &.fa-instagram {
                    
                        &:after {
                            left: -8px;
                            background-color: #8a3ab9;
                        }
                    }
                    &.fa-twitter {
                    
                        &:after {
                            background-color: #0084b4;
                        }
                    }
                }
                
                &__hashtag {
                    font-size: 1em;
                    font-weight: bold;
                    letter-spacing: 0.035em;
                }
            }
        }
        
        &-menu {
            
            list-style: none;
            padding-left: 0;
            margin: 0 auto;

            & > .menu-item {

                display: inline-block;
                position: relative;
                margin-right: 1.5em;

                & > a {
                    color: #fff;
                    font-weight: normal;
                
                }

                &:after {
                    content: "|";
                    position: absolute;
                    right: -14px;
                    top: 0;
                    color: #fff;

                    @include media-breakpoint-up(lg) {
                        right: -19px;
                    }
                }

                &:last-of-type {
                    &:after {
                        display: none;
                    }
                }

            }
            
             &--wrapper {
               
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                border-top: 1px solid #8C8E8D;
                padding: 1em 0;

                
            }
            
            &--item {
                display: inline-block;
                position: relative;
                margin-right: 1.5em;
                
                a {
                    color: #fff;
                    font-weight: normal;
                }

                &:after {
                    content: "|";
                    position: absolute;
                    right: -14px;
                    top: 0;
                    color: #fff;

                    @include media-breakpoint-up(lg) {
                        right: -19px;
                    }
                }

                &:last-of-type {
                    &:after {
                        display: none;
                    }
                }
            }
        }

    }

}