/////////////////////////////////////////////
//** Variables **//
//** This is the file for basic overrides of bootstraps variables. **//
//** You can accomplish a lot of the site setup here. If you need access to more, checkout bootstrap_components/bootstrap/ and find the variables file. **//
/////////////////////////////////////////////

/////////////////////////////////////////////
//** Table of Contents **//
// Grid Settings
// Body
// Links
// Fonts
// Page Builder Settings
/////////////////////////////////////////////
//** Grid settings **//
$main-sm-columns:       12;
$sidebar-sm-columns:    4;
/////////////////////////////////////////////
//** Body **//
//** Settings for the `<body>` element. **//

//$body-bg:       $white !default;
//$body-color:    $gray-900 !default;
/////////////////////////////////////////////
//** Links **//
//** Style anchor elements. **//
//$link-color:            theme-color("primary") !default;
//$link-decoration:       none !default;
//$link-hover-color:      darken($link-color, 15%) !default;
//$link-hover-decoration: underline !default;
/////////////////////////////////////////////
//** Fonts **//
//** Font, line-height, and color for body text, headings, and more. **//
/////////////////////////////////////////////
//** FONT FAMILIES **//
$font-family-sans-serif: 'PT Sans',-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;
$font-family-heading:   'Fredoka One', cursive;
//$font-family-monospace:  Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:       $font-family-sans-serif !default;
/////////////////////////////////////////////
//** FONT SIZES **//
$font-size-base: 1.125rem !default; // Assumes the browser default, typically `16px`
//$font-size-lg:   1.25rem !default;
//$font-size-sm:   .875rem !default;
$h1-font-size: 3.375rem !default;
$h2-font-size: 3rem !default;
$h3-font-size: 2.25rem !default;
$h4-font-size: 2rem !default;
//$h5-font-size: 1.25rem !default;
//$h6-font-size: 1rem !default;
/////////////////////////////////////////////
//** FONT MARGINS **//
$h1-margin-bottom: 1rem; // 16px
$h2-margin-bottom: 0.875rem; // 14px
$h3-margin-bottom: 0.75rem; // 12px
$h4-margin-bottom: 0.6875rem; // 11px
$h5-margin-bottom: 0.625rem; // 10px
$h6-margin-bottom: .5rem; // 8px
//** FONT WEIGHTS **/
//$font-weight-normal: normal !default;
//$font-weight-bold: bold !default;
//$font-weight-base: $font-weight-normal !default;
$line-height-base: 2 !default;
/////////////////////////////////////////////
//** HEADINGS **//
//$headings-margin-bottom: ($spacer / 2) ];
//$headings-font-family:   inherit !default;
//$headings-font-weight:   500 !default;
//$headings-line-height:   1.1 !default;
//$headings-color:         inherit !default;
/////////////////////////////////////////////
/////////////////////////////////////////////
//** GENERAL CONTENT **//
$paragraphs-margin-top: 0 !default;
$paragraphs-margin-bottom: 1rem !default;
$paragraphs-font-family:   inherit !default;
$paragraphs-font-weight:   normal !default;
$paragraphs-line-height:   $line-height-base;
$paragraphs-color:         #3B3B3A !default;
/////////////////////////////////////////////

//** PAGE BUILDER SETTINGS **//
$page-builder-block-spacing: 30px;
/////////////////////////////////////////////


