.contact-form {
    padding: 4em 2em;
    background-color: #4975AC;

    h3 {
        color: #fff;
        text-align: center;
    }
}

.contact.feature-image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-bottom: 3em;
    padding-bottom: 56%;
    
    @include media-breakpoint-up(lg) {
        padding-bottom: 36%;

    }
}


.post--content-limit {

    @include media-breakpoint-up(lg) {
        max-width: 80%;

    }
}