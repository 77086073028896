.pre-header {
	display: none;
	text-align: right;
	// padding-top: 0.5em;
	// text-transform: uppercase;
	// color: #fff;
	

	&--donate-btn {
		a {
			font-weight: bold;
			letter-spacing: 0.025em;
			color: #fff;
			text-transform: uppercase;

			&:hover {
				color: darken( #fff, 5);
				text-decoration: none;
			}
		}
	}
	@include media-breakpoint-up(lg) {
		display: block;
	}


	&--social-icons,
	&--donate-btn {
		display: inline-block;
	}

	&--social-icons {
		margin-right: 1em;
		margin-bottom: 0;
	}
}

.accent {
	&-bubbles {
		&__left {
			max-width:80px;
			display:inline-block;
			position: absolute;
			left: -100px;
			top: -16px;

			& img {
				width: 100%;
			}
		}

		&__right { 
			max-width: 180px !important;
			height: unset !important;
			position: absolute;
			top: -90px;
			right: -45px; 
			z-index: -1;
		}
	}
}