.donatekid {
        position: absolute;
        top:0;
        right: 20px;
        // height: 88px;
        // width: 200px;
        border-radius: 0 0 4px 4px;
        background-color: #FAD458;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: flex-end;

        &:hover {
            background:darken( #FAD458, 5);
        }


        &-inner {
            width: 100%;
            height: 100%;
            position: relative;
            display: block;
            // padding-top:3.2em;
            // padding-left:0.2em;
            padding: 4em 1em 0.2em 1em;
        }

        img {
            max-width: 200px;
            position: absolute;
            top:-96px;
            left: 3%;
        }
        a {
            vertical-align: baseline;
            margin: 0 auto;
            color: #fff;
            font-size: 24px;
            line-height: 1.5;
            font-family: "Fredoka One", sans-serif;
            letter-spacing: 1px;
        }

}

