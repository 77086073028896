.colophon {
    width: 100%;
    display: block;
    text-align: center;
    padding: 1em 0;
    font-size: 0.6em;

    a {
        color: #555;

        &:hover {
            color: #8dc63f;
        }
    }
}