.footer-readmore {
	background-color: #4975AC;

	&--left {
		padding: 6.17em 2em;
		display: flex;
		flex-direction: column;
		align-items: left;
		justify-content: flex-end;

		h3 {
			color: #fff;
			font-size: 2.4245em;
			letter-spacing: 2px;
		}
		button {
			margin-top: 2em;
		}
	}
	&--right {
		display: none;
		text-align: right;

		@include media-breakpoint-up(lg) {
			display: flex;
		}
	}
	&--img {
		height: 100%;
		width: 100%;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
	}
}