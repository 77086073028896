.feature-block {

	position: relative;
	overflow: hidden;
	width: 100%;
	padding: 3rem !important;
    display: flex;
    flex-direction: column;
    justify-content:  left;
    align-content: center;
	z-index: 1;

	@include media-breakpoint-up(lg) {
		height: 560px;
		min-height: 560px;
	}
	> h2,
	> p {
		color: #fff;
		max-width: 100%;
		line-height: 1.75;
		
		@include media-breakpoint-up(lg) {
			max-width: 50%;
		}
	}

	> h2 {
		line-height: 1.4;
	}

	> img {

		display: none;
		position: absolute;
		bottom: 0;
		right: 0;
		width: 15em;
		z-index: -1;


		@include media-breakpoint-up(lg) {

			display: block;
		}
		
	}

	&--event {
		background: #EFA843;
	}
	&--story {
		background: #4975AC;
	}
	&--sponsor {
		background: #6BC1B5;
	}

}