.page-header {
	padding-top: 1em;
}
.homepage-content {
	padding: 4em 0;
	overflow: hidden;
}

.donate-fixed-container {
	z-index: 999999;
	position: fixed;
	display: none;
	background-color: #fff;
	width: 100%;
	text-align: center;
	padding: 10px 10px 15px;
	overflow: hidden;
	background-color: #6bc1b5;
	bottom: -70px;
	transition: 100ms linear bottom;
	
	&.active {
		bottom: 0;
		display: block;
		transition: 100ms linear bottom;

	}

	.dontate-heart {
		width: 20px;
	}

	.btn-fixed-donate {
		@extend .btn;
		color: #fff;
		font-size: 1.2rem;
		font-family: Fredoka One,cursive;
		position: relative;
		border-radius: .5rem;
		padding: .75rem 2rem;
		background-color: #fad458;


		&:hover {
			background-color: darken( #fad458, 15);
		}
	}
}

