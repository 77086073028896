.home-slider {
    max-width: 2300px;
    margin: 0 auto;

    &--parent {
        list-style: none;
        overflow:hidden;
        padding: 0;
    }
    &--item {
        background-size: cover;
        background-position:bottom;
        display: inline-block;
        padding-bottom: 34.66%;
    }

}
#sponsors {

    .sponsor-slider {
        margin-top: 1em;
        overflow: hidden; 
        padding-left: 0;
        &--img {
            max-width: 75%; 

            @include media-breakpoint-up(xl) {
                max-width: 200px;
            }
        }
    }
}

