.nav-primary {

	@include media-breakpoint-up(md) {
		display: flex;
		flex-direction: column;
		align-content: center;
		margin: auto;
	}
	
	ul {
		list-style-type: none;
		display: flex;
		align-items: center;
		flex-direction: row;
		margin: auto;
		width: 100%;
		min-height: 5vh;


		> li {
			margin: auto;
			// display: inline-block;
			
			> a {
				color: $paragraphs-color;
			}
		}
	}
}

// .banner {
// 	@include media-breakpoint-up(lg) {
// 		margin-bottom: 1em;
// 	}
// }

.brand {
	width: 100%;
	height: 100%;
	&--logo {
		width: 80%;
		// min-height:100px;
		background-size: contain;
		background-repeat: no-repeat;
		display: block;
		position: relative;
		text-align: center;
		padding: 0.5em 0;
		margin: 0 auto;

		@include media-breakpoint-up(lg) {
			text-align: left;
		}

		& > img {
			height: 70px;
			@include media-breakpoint-up(lg){
				height: unset;
				max-width: 60%;
			}
		}
	}

}

.wrap {
	margin-top: 90px;

	@include media-breakpoint-up(lg) {
		margin-top: 0;
	}
}

.donate-mobile {

	// padding: 1rem;
	// margin: 1rem;
	// color: #3b3b3a;
	// line-height: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%; 
	text-decoration: none !important;

	& > p {
		box-shadow: 0px 1px 4px 1px rgba(0,0,0,.1);
		padding: .75em 1em;
		margin-bottom: 0;
		margin-top: 1px;
		line-height: 1;
		border-radius: 8px;
		color: #6bc1b5;
		font-weight: bold;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		text-decoration: none !important;

		&:active,
		&:hover,
		&:focus {
			background-color: #fad458;
		}

		img {
			fill: #6bc1b5;
			width: 15px;
			width: 15px;
			margin: 0 5px 0 2px;

		}
	}

	// position: absolute;
	// left: 1rem;
	// top: -5px;
	// font-size: 28px;
	
}


.header {
	&-social {
		list-style: none;
		padding-left: 0;
		margin: 1em 0;
		
		a {
			color: #fff;

			&:hover {
				color: darken(#fff,5);
			}
		}

		& > li {
			display: inline-block;
		}

		&--item {
			margin-right: 1.5em;
			
			& .fab,
			& .fa {
				position: relative;
				font-size: 0.8em;
				z-index: 999;

				&:after {
					content: "";
					width: 100%;
					height: 100%;
					padding: 1em;
					position: absolute;
					border-radius: 50vw;
					left: -11px;
					top: -8px;
					z-index: -1;
				}
				
				&.fa-facebook {
				
					&:after {
						background-color: #4975AC;
					}
				}
				&.fa-instagram {
				
					&:after {
						left: -8px;
						background-color: #8a3ab9;
					}
				}
				&.fa-twitter {
				
					&:after {
						background-color: #0084b4;
					}
				}
			}
			
			&__hashtag {
				font-size: 1em;
				font-weight: bold;
				letter-spacing: 0.0025em;
			}
		}
	}
}

#Capa_1 {
	fill: #6bc1b5; 
}