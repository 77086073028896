.page-header {

    font-size: 3rem;
    background-color: #6BC1B5;
    padding: 1em 0;

    & h2 {
        color: #fff;
        margin: 0;

    }

}