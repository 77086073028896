html {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.25), only screen and ( min-device-pixel-ratio: 1.25), only screen and ( min-resolution: 200dpi), only screen and ( min-resolution: 1.25dppx) {
	html {
		-webkit-font-smoothing: subpixel-antialiased;
	}

}

*:focus {
	outline: none;
}

label,
input {
	font-family: $font-family-base;
}

//** Responsive Video Embeds **//
.embed-container {
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 30px;
	height: 0;
	overflow: hidden;
	max-width: 100%;

	iframe, object, embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
img{
	// width: 100%;
	height: auto;
	max-width: 100%;
}
*
{
	box-sizing: border-box;
}

.main {
	padding: 0 !important;
}

.content-split {
	
	margin: 4em 0;
	
	& .row {
		@include media-breakpoint-down(md) {
			flex-direction: column-reverse;
		}
	}

	&--image {
		text-align: center;
		margin-bottom: 3em;

		& img {
			width: 100%;
		}
	}

}

pre {
		margin: 20px; padding: 20px; 
		color: #aaa; background-color: #222;
		white-space: pre; text-shadow: 0 1px 0 #000;
		border-radius: 15px; border-bottom: 1px solid #555;
		box-shadow: 0 1px 5px rgba(0,0,0,0.4) inset, 0 0 20px rgba(0,0,0,0.2) inset;
		font: 14px/24px 'Courier New', Courier, 'Lucida Sans Typewriter', 'Lucida Typewriter', monospace;
		font-weight: bold;
}

.hide-lg {
	@include media-breakpoint-up(lg){
		display: none !important;
	}
}

.hide-lg-down {

	@include media-breakpoint-down(lg) {
		display: none !important;
	}

}

.hide-xl-down {

	@include media-breakpoint-down(xl) {
		display: none !important;
	}

}

.align-right {
	@include media-breakpoint-up(lg){
		text-align: right;
	}
}


// Form Styles 

#gform_fields_2 {
	li {

		& label {
			color: #fff;
			font-size: 18px;
			font-family: "Fredoka One", sans-serif;
			margin-bottom: 0;
			letter-spacing: 1px;
			padding-left: 0.5em;
		}
		& .ginput_container {
			margin: 0;
		}
		& input,
		textarea {
			margin: 0;
			border-radius: 8px;
			padding: 0.5em;
			border: none;
		}

		@include media-breakpoint-up(lg) {
			display: inline-block;
			width: 100%;
		}
	}
	.gform-halfwidth {

		@include media-breakpoint-up(lg) {
			width: 50% !important;
		}
	}
}

.gform_footer {
	text-align: right;

	input {
		font-size: 1.2rem;
		background: #FFF;
		color: #333;
		font-family: $font-family-heading;
		position: relative;
		border-radius: 0.5rem;
		padding: .3rem 1.5rem;
		border: none;
		box-shadow: 7px 7px 1px #fad458;

		&:hover {
			background: darken(#fff, 10) !important;
			cursor: pointer !important;
		}
		
	}
}

//Woocommerce 
.tribe-events-tickets {
	background: #fff;
	border: 1px solid #efefef;

	
}

.tribe-events-tickets td.woocommerce,
.tribe-events-tickets td.tickets_name,
.tribe-events-tickets td.tickets_price,
.tribe-events-tickets td.tickets_description {
	border-bottom: none;
	padding: 10px;
	vertical-align: middle;
	text-align: center;

	@include media-breakpoint-up(lg) {
		text-align: left;
	}
}


.section-entry {

	@include media-breakpoint-up(lg) {
		padding: 4em 0;
	}
}


.tribe-bar-disabled,
.tribe-events-list-separator-month,
.tribe-events-ical,
.tribe-events-gcal,
.tribe-events-back {
	display: none !important;
}



.stripe-card-group {

	& i {

		top: 18px !important;
	}
}

.type-tribe_events.has-post-thumbnail {
	.tribe-events-event-image {
		display: none;
	}
}

.tribe-events-schedule {

	& > * {
		display: none !important;
	}
}
.tribe-events-footer {
	display: none;
}

.tribe-events-meta-group-details,
.tribe-events-meta-group-organizer,
.tribe-events-meta-group-venue,
.tribe-link-view-attendee {
	font-family: 'PT Sans', san-serif;
}

table.tribe-events-tickets {
	display: block !important;
	width: 100%;
	& > tbody {
		width: 100%;
		display: table;
		// display: block;
	}
	& .woocommerce.tribe-tickets-form-row {
		display: block;
		width: 100%;

		& > td {
			width: 25%;
			display: inline-block;
		}
	}
}

.pl-0 {

	@include media-breakpoint-up(lg) {
		text-align: right;
	}
}

.event-listing-container {
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid rgba(0,0,0,0.2);
	border-radius: 15px;
	overflow: hidden;
	flex-direction: column;
	
		@include media-breakpoint-up(lg) {

			flex-direction: row;
		}
	
	&--left {

		width: 100%;

		@include media-breakpoint-up(lg){
			
			width: 60%;

		}
		
		& > div {

			padding-bottom: 62%;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;

		}
	}
	
	&--right {

		width: 100%;
		padding: 2rem;
		display: flex;
		justify-content: center;
		flex-direction: row;
		
		@include media-breakpoint-up(lg) {
			
			flex-direction: column;
			width: 40%;
			padding: 2rem;

		}

		h2 {

			a {
	
				color: #3b3b3b;
				font-family: fredoka one, sans-serif;
			}
		
		} 
		
		.entry-summary {
			margin-top: auto;
		}

		.tribe-event-url {
			font-size: 32px;
			line-height:47px;
		}
	}
}

.tribe-events-sub-nav {

	li {

		a {
			@extend .btn-secondary;
			padding: 0.75em 1em;
			margin-bottom: 0.5em;
			display: inline-block;
			border: 1px solid rgba(0,0,0,0.1) !important;
			border-radius: 8px;
		}
		span {
			display: none;
		}
	}
}

.tribe-events-event-meta.primary {
	border: none;
	
	a {
		color: #3b3b3b;
	}

}
.tribe-events-event-meta.secondary {
	border: none;
	background-color: rgba(0,0,0,0.07);

	a {
		color: #3b3b3b;
	}
}
.single-tribe_events .tribe-events-event-meta {
	font-size: 1rem;
}

.tribe-events-content {
	img {
		max-width: 100%;
	}
}

.single-tribe_events {

	.tribe-events-sub-nav {
		display: none;
	}
}

.woocommerce .button.alt:hover {
	background-color: #6bc1b5 !important;
}

#ywcnp_form_name_your_price + .quantity {
	display: none;
}

.product-image-wrapper {

	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	width: 100%;
	padding-bottom: 100%;
	height: 100%;


	@include media-breakpoint-up(md) {
		padding-bottom: 52%;
	}
	@include media-breakpoint-up(lg) {
		padding-bottom: 62%;
	}

}

.content-body {
	 
	ul {
	
		margin-bottom: 0;
	
		li {
			padding-left: 1em;
			position: relative;
			margin-bottom: 1em;

			&:before {
				content:"";
				width: 5px;
				height: 5px;
				border-radius: 50%;
				background-color: #333;
				position: absolute;
				left: 0;
				top: 0.75em;
			}
		}
	}
}




// Tribe Plugin Overrides 
#tribe-tickets {
	max-width: 100%;

	.tribe-tickets__item {
		font-family: PT Sans, sans-serif;

		.tribe-tickets__item__extra__available {
			font-size: 12px;
			font-weight: bold;
			text-transform: uppercase;
			color: #333;

			& > span {
				font-size: 12px;
				font-weight: bold;
				text-transform: uppercase;
				color: #333;
			}
		}

		.tribe-tickets__item__quantity {
			button {
				padding: 0 15px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				line-height: 1;
				background-color: #6bc1b5;
				color: white;
				border: 0px solid transparent;
				font-size: 34px;

				&:hover {
					background-color: darken(#6bc1b5, 15%);
					color: #fff;
				}
			}
		}
	}	

}
.single-tribe_events {
	.tribe-common {
		.tribe-tickets__buy {
			&.tribe-common-c-btn {
				background-color: #6bc1b5;
				font-family: Fredoka One, cursive;

				&:hover {
					background-color: darken(#6bc1b5, 15%);
				}
			}
		}
	}
}

#tribe-events-pg-template .tribe-events-content h2 {
	font-size: 26px;
}