#sponsors {
    background-color: #ECECEC;
    padding: 4rem;
    text-align: center;

    ul {
        list-style: none;
        li {
            display: inline-block;
        }
    }
}
