.background-image
{
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.offset-yellow {
  position: relative;
  display: inline-block;
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 5%;
    left: 5%;
    background: #FAD458;
    z-index: -1;
  }
}

.flex {
  &-center {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}


@include media-breakpoint-down(md) {
  .mob-center {
    text-align: center;
  }
}