.event {

    &-details {
        margin: 4em 0;
        padding-top: 2em;
        border-top: 1px solid rgba(0,0,0,0.3);

        &--heading {
            font-size: 24px;
            font-family: "PT Sans";
            color: #6BC1B5;
            line-height: 32px;
        }
        
        
        > div {
            display: inline-block;
            margin-left: -4px;
        }
        
        &--datetime {
            width: 100%;
            @include media-breakpoint-up(lg) {
                width: 50%;
            }
        }

        &--contact {
            width: 100%;
            @include media-breakpoint-up(lg){
                width: 50%;
            }
        }

        &--map {
            width: 100%;
            padding: 2em;
            background: #efefef;
            display: flex !important;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            @include media-breakpoint-up(lg) {
                flex-direction: row;
            }

            p {
                line-height: 1.6;
                margin: 0;
            }
            
            &__left {
                width: 100%;
                margin-bottom: 1em;
                // display: inline-block;
            }
            &__right {
                width: 100%;
                min-height: 20vh;
                position: relative;
                overflow: hidden;

                & iframe {
                    left:0;
                    top:0;
                    height:100%;
                    width:100%;
                    position:absolute;
                }
                // display: inline-block;
                
            }
        }

        &--purchase {
            width: 100%;
            margin: 1em;
            padding: 1em;
            border: 1px solid rgba(0,0,0,0.3);
        }

    }
}


//Woocommerce event override 

.tribe-events-tickets-title {
    font-size: 24px;
    font-family: "PT Sans";
    color: #6BC1B5;
    line-height: 32px;
    margin: 0 0 0.4em 0;
    padding: 0;
    font-weight: 300;
}