.banner {

	position: fixed;
	top: 0;
	width: 100%;
	z-index: 999;
	background: #fff;
	@include media-breakpoint-up(lg) {
		position: relative;
	}

	.header-offset {

		@include media-breakpoint-up(lg) {
			margin-top: -70px;
		}
	}

	.row:first-of-type > div {
		z-index: 90210;
	}
}

.nav-primary {

		position: fixed;
		background: #fff;
		width: 100%;
		height: 100%;
		z-index: 999;
		top: 80px;
		left: -100%;
		animation: 0.5s slideOut;

		@include media-breakpoint-up(lg) {

			display: block;
			position: relative;
			height: 1px;
			left: 0;
			top: 50%;
			background: unset;

		}

	ul {

		display: block;
		// padding: 0.5em !important;
		margin: 0;

		> li { 
			 display: block;
			 width: 100%;
			 height: 100%;
			//  padding: 1em;
			 border-bottom: 1px solid #efefef;

			 @include media-breakpoint-up(lg) {
				 display: inline-block;
				 width: unset;
				 border: 0;
			 }

			 & > a { 
				width: 100%;
				height: 100%;
				display: block;
				padding: 0.55em;
				
				font-weight: bold;
		
		
				&:hover {
					text-decoration: underline;
					color:#6bc1b5;
					cursor: pointer;
				}
			}
		}
		@include media-breakpoint-up(lg) {
			display: inline-block; 
		}
	}

	.menu-item-has-children {
		position: relative;

		&:hover {
			.nav-drop {
				display: inline-block;
				opacity: 1;
				transition: 500ms linear opacity;
			}
		}
	}
	
	.nav-link-item {}
	.nav-drop {
		display: none;
		opacity: 0;
		position: absolute;
		width: 200px;
		background-color: #fff;
		border-radius: 0px 0px 8px 8px;
		box-shadow: 2px 4px 6px 0px rgba(0,0,0,0.2);
		overflow: hidden;
		left: 0;
		transition: 500ms linear opacity;

		.nav-drop-item {
			font-size: 14px;
			text-align: left;
			padding-left: 10px;
			padding-bottom: 10px;
			line-height: 1.6;
		}
		.nav-drop-link {

			&:hover {
				color: #333;
			}
		}
	}
	.nav-drop-item {}
	.nav-drop-link {}

}

.menu-open {
	
	overflow: hidden;

	@include media-breakpoint-up(lg) {
		overflow: auto;
	}
	.nav-primary {

		@include media-breakpoint-down(md) {
			
			left:0%;
			animation: 0.75s slideIn;
		}
	}
	.mobile-menu {
		z-index: 90210;
		top: 10%;

	}
}


.mobile-menu {
	
	padding: 1em;
	margin: 1.3em 1em 1em 1em;
	display: inline-block;
	position: absolute;
	right: 1em;
	top: 10%;

	.burger {
		display: block;
		position: relative;
		width: 25px;
		height:2px;
		background: #333;
		margin:0;

		&:before {
			content: "";
			display: block;
			position: absolute;
			width: 25px;
			height: 2px;
			background: #333;
			top: -5px;
		}

		&:after {
			content: "";
			display: block;
			position: absolute;
			width: 25px;
			height: 2px;
			background: #333;
			top: 5px;
		}
	}
}
@include media-breakpoint-down(lg) {

	@keyframes slideIn {
		0%   { left: -100%; }
		100% { left: 0%; }
	}
	
	@keyframes slideOut {
		0%   { left: 0%; }
		100% { left: -100%; }
	}
}