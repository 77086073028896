// Search form
.search-form {
	@extend .form-inline;
}
.search-form label {
	font-weight: normal;
	@extend .form-group;
}
.search-form .search-field {
	@extend .form-control;
}
.search-form .search-submit {
	@extend .btn;
	@extend .btn-secondary;
}


//contact form
.contact-form {
	.gform_wrapper {
		max-width: 900px;
		margin: 0 auto;
	}
	.gform-halfwidth,
	.gform-textarea-contact {
		text-align:  center;
		@include media-breakpoint-up(lg) {
			text-align: left;
		}
	}

	.gform-textarea-contact textarea {
		padding: 1.5em !important;
	}

}


.wc-stripe-elements-field {
	width: 100%;
	border-radius: 8px;
	padding: 15px !important;

	& > div {

		& > iframe {

		}
	}
}
.stripe-card-group {
	width: 100%;
}
