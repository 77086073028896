.sponsor-grid {
  background-color: #4975AC;
  padding: 4em 0;
}

#og-grid {
  background: #fff;
  position: relative;
}
.imagelistexpander-items {
  font-size: 0;
}
.imagelistexpander-item {
  display: inline-block;
  vertical-align: top;
  -webkit-transition: height 500ms ease;
  -o-transition: height 500ms ease;
  transition: height 500ms ease;
}
.imagelistexpander-expander {
  position: absolute;
  left: 0;
  right: 0;
  overflow: hidden;
  max-height: 0;
  -webkit-transition: max-height 500ms ease;
  -o-transition: max-height 500ms ease;
  transition: max-height 500ms ease;
}
.imagelistexpander-contents {
  position: relative;
}
.imagelistexpander-contents:after {
  display: block;
  position: absolute;
  bottom: 0px;
  left: 50%;
  margin-left: -30px;
  width: 0;
  height: 0;
  content: '';
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  border-bottom-style: solid;
  border-bottom-width: 0px;
  -webkit-transition: border-bottom-width 500ms ease;
  -o-transition: border-bottom-width 500ms ease;
  transition: border-bottom-width 500ms ease;
}
.imagelistexpander-item.active .imagelistexpander-contents:after {
  border-bottom-width: 30px;
}


.gallery-items { 
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  flex-wrap: wrap;
  font-size: 0; 
}

.gallery-item {
  display: inline-block;
  vertical-align: top;
  width: 33.33%;
  background: #fff;
  min-height: 200px;
  @include media-breakpoint-up(lg) {
    min-height: 245px;
  }
  border: 1px solid rgba(0,0,0,0.1);
  -webkit-transition: height 500ms ease;
  -o-transition: height 500ms ease;
  transition: height 500ms ease;
}

.gallery-expander {
  position: absolute;
  left: 0;
  right: 0;
  overflow: hidden;
  max-height: 0;
  -webkit-transition: max-height 500ms ease;
  -o-transition: max-height 500ms ease;
  transition: max-height 500ms ease;
}

.gallery-contents { 
  position: relative; 
  margin-top: 1.5em;
}

.gallery-contents:after {
  display: block;
  position: absolute;
  bottom: -20px;
  left: 50%;
  margin-left: -30px;
  width: 0;
  height: 0;
  content: '';
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  border-bottom-style: solid;
  border-bottom-width: 0px;
  -webkit-transition: border-bottom-width 500ms ease;
  -o-transition: border-bottom-width 500ms ease;
  transition: border-bottom-width 500ms ease;
}

.gallery-item.active .gallery-contents:after { border-bottom-width: 30px; }


.gallery-item.active {
  // margin-bottom: 2em;

  & .gallery-expander {
    margin-top: 0.5em;
  }
}
body {
  font-family: 'Nanum Barun Gothic';
  font-weight: 200;
  color: #555;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;
}

.gallery-item {
  text-align: left;
  font-size: 25px;
  // margin: 0 10px;
  padding: 10px 0;
}

.gallery-item .thumbnail {
  position: relative;
  width: 100%;
  padding: 2em;
  min-height: 150px;
  overflow: hidden;
  cursor: pointer;
}

.gallery-item .thumbnail > img {
  // position: absolute;
  // top: 50%;
  // bottom: 0;
  // margin-top: -300px;
  max-width: 300px;
}

.gallery-item .title {
  text-align: left;
  margin-top: 20px;
  padding-bottom: 20px;
}

.gallery-contents:after { border-bottom-color: #767673; }

.gallery-expander { 	
  background-color: #767673;
 }

.gallery-expander-contents {
  margin: 0 auto;
  width: 1000px;
  padding: 1em
}

@media (min-width: 640px) and (max-width: 999px) {

.gallery-expander-contents { width: unset; }
}

@media (max-width: 639px) {

.gallery-expander-contents { width: 100%; }
}

.gallery-expander-contents:after {
  clear: both;
  display: table;
  content: '';
}

.gallery-expander-contents > div.col {
  // float: left;
  // width: 480px;
}

@media (min-width: 640px) and (max-width: 991px) {

.gallery-expander-contents > div.col { width: 100%; }
}

@media (max-width: 639px) {

.gallery-expander-contents > div.col {
  float: none;
  padding: 25px 20px 0;
  width: auto;
}

}

.gallery-expander-contents > div.col + div.col { margin-left: 40px; }

@media (min-width: 640px) and (max-width: 999px) {

.gallery-expander-contents > div.col + div.col { 
  margin-top: 20px;
  margin-left: 0;
 }

}

@media (max-width: 639px) {

.gallery-expander-contents > div.col + div.col { 
  margin-top: 20px;
  margin-left: 0;
 }

}

.gallery-expander-contents div.close {
  position: absolute;
  top: 10px;
  right: 20px; 
  z-index:999;
   i {
     color: #fff;

     &:hover {
       color: darken(#fff, 10);
     }
   }
}

.gallery-expander-contents h5.title {
  font-weight: 300;
  color: #fff;
  font-family: "Fredoka One", sans-serif;
  letter-spacing: 1px;
  font-size: 36px;
  line-height: 1;
  // margin-bottom: 0;
  padding-bottom: 0;
}

.gallery-expander-contents div.contents {
    // margin-top: 10px;
    // height: 227px;
    // width: 560px;
    // color: #FFFFFF;
  font-family: "PT Sans";
  font-size: 18px;
  line-height: 32px;
  padding: 0;
  margin: 0;
  // font-family: 
  // font-size: 14px;
  // line-height: 24px;
  color: #fff;
}

.gallery-expander-contents div.image > img {
  width: 100%;
  display: block;
}

.gallery-expander-contents .image {
  background: #fff;
  padding: 2em;
}