.block.page-builder
{
	padding: $page-builder-block-spacing 0;
}


.yt-responsive {
		position: relative;
		width: 100%;
		height: 0;
		padding-bottom: 67%;
	
	& > .pb-video {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0; top: 0;
	}
}
.ytplayer--wrapper {
	padding-bottom: 4em;
}


.imagebytext {
	margin: 2em 0;

	.reverse {
		flex-direction: row-reverse;
	}

	&--text {

		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		& > * {
			align-self:flex-start;
		}
	}
	&--img {
		margin-bottom: 1em;

		& img {
			width: 100%;
		}
	}
}