#subscribe {
    background-color: #4975ac;
    padding: 2em;
    text-align: center;

    p {
        margin: 0;
    }
    h2 {
        color: #fff;
    }


    input[type=submit] {
        display: block;
        background: #fff;
        border-radius: 8px;
        border: none;
        padding: 0.25em;
        font-size: 24px;
        font-family: "Fredoka One";
        width: 170px;
        margin: 0 auto;
    }

    #mc_embed_signup {
        background: unset;
        font-family: "Fredoka One", sans-serif;
    }
    #mc_embed_signup_scroll {
        vertical-align: top;
        display:flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
        justify-content: center;

        @include media-breakpoint-up(lg) {
            flex-direction: row;
            align-items: unset;
        }
    }
    #mce-responses {
        display: none;
    }

    .mc-field-group {

        width: 50% !important;

        @include media-breakpoint-up(lg) {
            width: 33% !important;
            min-width: 300px;
            max-width: 500px;
            padding-right: 2em;
            display: inline-block;
            // margin-left: -4px;
            // vertical-align: top;
        }

        input {
            font-size: 24px;
            color: #3B3B3A;
            border: none;
            border-radius: 8px;
            padding: 0.4em !important;
        }
    }
    .clear {
        display: inline-block;
        width: unset;
        min-height: 50px;
        input {
            font-size: 24px;
            color: #3b3b3a;
            float: left;
            line-height: 1;
            padding: 0.5em 1.2em;
            height: unset;
            width: unset;
        }
        
    }
}