.woocommerce-breadcrumb,
.woocommerce-result-count,
.woocommerce-ordering {
	display: none;
}

#woocommerce-category {

	margin: 4em 0;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;

	@include media-breakpoint-up(lg) {
		justify-content: left;
	}

	article {
		// margin-right: 1em;
		margin-bottom: 2em;
		text-align: center;
		width: 100%;
		
		@include media-breakpoint-up(lg) {
			width: 33%;
			margin-bottom: 1.5em;
		}

		img {
			margin-bottom: 0.5em;
		}

		.media-content {
			display: flex;
			flex-direction: column;
			align-content: center;
			justify-content: center;
			padding: 0 4em 4em 4em;

			@include media-breakpoint-up(lg) {
				padding: 1em;
			}

			.entry-title {
				a {
					font-size: 18px;
					color: #333;
				}
			}
			.price {
				text-align: center;
			}
		}
	}

}

.woocommerce {
	// margin: 4em 0;
	.col-1,
	.col-2 {
		padding-left:0;
		max-width: 100%;

		@include media-breakpoint-up(lg) {
			max-width: 50%;
		}
	}

	.woocommerce-billing-fields {
		margin-bottom: 2em;

		input,
		textarea {
			padding:0.45em;
			border-radius: 3px;
			border: 1px solid rgba(0,0,0,0.3);
		}
	}

	.woocommerce-additional-fields {
		textarea {
			padding: 0.45em;
			border-radius: 3px;
			border: 1px solid rgba(0,0,0,0.3);
		}
	}
}

.tribe-events-tickets .woocommerce .quantity input {
	border: 1px solid rgba(0,0,0,0.1);
}

.woocommerce #respond input#submit.alt, .woocommerce a.button.alt, .woocommerce button.button.alt, .woocommerce input.button.alt {
	background-color: #fad458;
	border: 1px solid darken(#fad458,5);
}

#woocommerce-category article .media-content .price {
	display: none;
}

.page--entry {

	padding: 8em 0;

	& .product_meta {
	
		display: none;
	
	}
}

.page--spacing {

	margin: 4em 0;
}

form.cart { 

	& > * {
	
		display: inline-block;
		margin-right: 2em;

	}
}