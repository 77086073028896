.stories-selector {
	background-color: rgba(73,117,172,0.9);
	padding: 4em 0;


	.stories-grid {
		list-style: none;
		padding-left: 0;
		
		&--item {
			padding: 0;
			// border: 1px solid red;
			overflow: hidden;
		}
	}

}
.stories-inner {
	&--wrapper {
		position: relative;

		
	
		.btn-stories {
			position: absolute;
			padding: 0.15em 2em 2em 2em;
			text-align: center;
			bottom: -45px;
			left: 25%;
			border: none;
			// transform: translate(55%);
			// transform-origin: center;
			font-size: 1.35em;
			color: #3b3b3a;
			background-color: #fad458;
			font-family: "Fredoka one", sans-serif;
			animation: slideDown 0.5s;

			&:hover {
				cursor: pointer;
				bottom:-25px;
				// animation-delay: 2s;
				animation: slideUp 0.7s;
			}
		}
	}

	&--img-wrapper {
		position: relative;
		overflow: hidden;
		height: 35vh;
		background-size: cover;
		background-repeat: no-repeat;

	}
}
@keyframes slideUp {
	0%   { bottom: -45px; }
	100% { bottom: -25px; }
}
	
@keyframes slideDown {
	0%   { bottom: -25px; }
	100% { bottom: -45px; }
}