.event-listing {

    &--wrapper {
        margin: 2em 0;
        padding: 0;
        list-style: none;
    }

    &--item {
        display: flex;
        flex-direction: column;
        align-content: center;
        margin-bottom: 4em;
        overflow: hidden;

        @include media-breakpoint-up(lg) {
            border-radius: 8px;
            flex-direction: row;
            margin-bottom: 2em;
        }
    }

    &--image {
        width: 50%;
        min-height: 25vh;
        margin: auto;
        position: relative;
        overflow: hidden;
        @include media-breakpoint-up(lg) {
            width: 60%;
            & > img {
                // max-height: 25vh;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                // height: 100%;
            } 
        }
    }
    &--content {
        width: 100%;
        height: auto;
        margin: auto;
        padding: 1em;
        text-align: center;
        
        @include media-breakpoint-up(lg) {
            border-radius: 0 8px 8px 0;
            border-top: 1px solid #efefef; 
            border-right: 1px solid #efefef;     
            border-bottom: 1px solid #efefef;     
            width: 40%;
            padding: 2em;
            text-align: left;
            margin-bottom: 0;
        }

        &__wrapper {
            @include media-breakpoint-up(lg) {
                min-height: 75%;
                overflow: hidden;
            }
        }
    }

    &--link {
        margin-top: 2em;
        width: 50%;
        font-size: 18px;

        @include media-breakpoint-up(lg) {
            width: 100%;
            margin-top: 1em;
        }

        &__wrapper {
            min-height: 20%;
        }
    }

}