.image-square {
  position: relative;
  height: 0;
  width: 100%;
  background-color: #eee;
  padding-bottom: 100%;
}

.image-rectangle {
  position: relative;
  height: 0;
  width: 100%;
  background-color: #eee;
  padding-bottom: 125%;
}
