button,
a {
    letter-spacing: 1px;
    &.btn-primary {
        font-size: 1.2rem;
        background: #6BC1B5;
        font-family: $font-family-heading;
        position: relative;
        border-radius: 0.5rem;
        padding: .75rem 2rem;
        display: inline-block;
        border: none;
        
        &:after {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: .5rem;
            background-color: #fad458;
            left: 7px;
            top: 7px;
            z-index:-1;
        }

        &:hover {
            background: #579c93;
            border: none;
            cursor: pointer;
        }
    }

    &.btn-secondary {
        font-size: 1.2rem;
        background: #fff;
        color: #6BC1B5;
        font-family: $font-family-heading;
        position: relative;
        border-radius: 0.5rem;
        padding: .3rem 1.5rem;
        border: none;
        
        &:hover {
            background: #EFEFEF;
            color: #6BC1B5;
            cursor: pointer;
        }
    }

}

.tribe-button {
        color: #fff;
        font-size: 1.2rem;
        background: #6BC1B5;
        font-family: $font-family-heading;
        position: relative;
        border-radius: 0.5rem;
        padding: .3rem 1.5rem;
        border: none;
        
        &:after {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: .5rem;
            background-color: #fad458;
            left: 7px;
            top: 7px;
            z-index:-1;
        }

        &:hover {
            background: #579c93;
            border: none;
            cursor: pointer;
        }

}